import { useRef } from 'react';

import './ContentVerticalList.scss';
import { srcSet, useDimensions } from '../../../helpers/image';

export default function ContentVerticalList({ list, title, length = 6 }) {
  const contentImg = useRef();
  const channelImg = useRef();
  const contentImgDimensions = useDimensions(contentImg);
  const channelImgDimensions = useDimensions(channelImg);

  return (
    <div className="contentVerticalList">
      <h6 className="contentVerticalList-title">{title}</h6>

      <ul className="contentVerticalList-list">
        {list.slice(0, length).map((item) => (
          <li key={item.id}>
            <a href={`/${item.uri}`} className="contentVerticalList-list-item">
              <div
                className="contentVerticalList-list-item-image"
                ref={contentImg}
              >
                {contentImgDimensions && (
                  <img
                    srcSet={srcSet(item.imageUrl, contentImgDimensions)}
                    alt="content-image"
                  />
                )}
              </div>

              <div>
                <div
                  className="contentVerticalList-list-item-channel-logo"
                  ref={channelImg}
                >
                  {channelImgDimensions && (
                    <img
                      srcSet={srcSet(
                        item.author.imageUrl,
                        channelImgDimensions
                      )}
                      alt="content-channel-logo"
                    />
                  )}
                </div>

                <p className="contentVerticalList-list-item-channel-name">
                  {item.author.displayName}
                </p>

                <p className="contentVerticalList-list-item-title">
                  {item.title}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
